import { useTranslation } from 'react-i18next';
import { PeopleTableRow, HandleInputChange, HandleEmailBlur } from '../types';
import EmailInput from './EmailInput';
import FormInput from './FormInput';
import cancelXImg from '../../../images/cancel-x.svg';
import {
  CancelXImg,
  CancelImgPlaceholder,
  AddPersonButton,
  FormRowContainer,
} from './styles';

interface FormRowProps {
  person: PeopleTableRow;
  removeRow: (rowIdx: number) => void;
  idx: number;
  handleInputChange: HandleInputChange;
  handleEmailInputChange: HandleInputChange;
  handleEmailBlur: HandleEmailBlur;
  numOfRows: number;
}

const FormRow = ({
  person,
  removeRow,
  idx,
  handleInputChange,
  handleEmailInputChange,
  numOfRows,
  handleEmailBlur,
}: FormRowProps): JSX.Element => {
  return (
    <FormRowContainer>
      <EmailInput
        inputVal={person.email}
        handleInputChange={handleInputChange}
        handleEmailInputChange={handleEmailInputChange}
        handleEmailBlur={handleEmailBlur}
        rowIdx={idx}
        showError={person.showEmailError}
      />

      <FormInput
        inputName="fullName"
        inputVal={person.fullName}
        handleInputChange={handleInputChange}
        rowIdx={idx}
      />

      {numOfRows > 1 ? (
        <CancelXImg onClick={() => removeRow(idx)} src={cancelXImg} />
      ) : (
        <CancelImgPlaceholder />
      )}
    </FormRowContainer>
  );
};

interface UploadManuallyFormProps {
  peopleRows: Array<PeopleTableRow>;
  handleInputChange: HandleInputChange;
  handleEmailInputChange: HandleInputChange;
  handleEmailBlur: HandleEmailBlur;
  addFormRow: () => void;
  removeRow: (rowIdx: number) => void;
}

const UploadManuallyForm = (props: UploadManuallyFormProps): JSX.Element => {
  const { t } = useTranslation('manual_upload');

  const buildFormRows = (peopleRows: Array<PeopleTableRow>) =>
    peopleRows.map((row, idx) => {
      return (
        <FormRow
          numOfRows={peopleRows.length}
          removeRow={props.removeRow}
          handleInputChange={props.handleInputChange}
          handleEmailInputChange={props.handleEmailInputChange}
          handleEmailBlur={props.handleEmailBlur}
          idx={idx}
          key={row.rowId}
          person={row}
        />
      );
    });

  return (
    <>
      {buildFormRows(props.peopleRows)}
      <AddPersonButton
        label={t('form.add_row_button')}
        variant="tertiary"
        onClick={props.addFormRow}
      />
    </>
  );
};

export default UploadManuallyForm;
