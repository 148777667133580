import {
  FC,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
  ApolloLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { useAccessToken } from 'hooks/useAccessToken';
import { Sentry } from 'lib/sentry';
import { sha256 } from 'crypto-hash';
import { config } from '../config';
import { SeverityLevel } from '@sentry/types';
import {
  generateGraphQLHeaders,
  ClientHeadersByApp,
} from '@robinpowered/dashboard-apps-common';

type ApolloContextValue = {
  tenantId: string | null;
  setTenantId: Dispatch<SetStateAction<string | null>>;
};

const httpLink = createHttpLink({
  uri: config.gqlUrl ?? '',
});

let persistedQueryLink: ApolloLink | undefined;

try {
  // IE11 & Edge will not be able to use crypto, so only add persisted queries
  // where it's available.
  if (
    typeof window?.crypto?.subtle?.digest !== 'undefined' &&
    typeof window?.TextEncoder !== 'undefined'
  ) {
    /**
     * Persisted query link allows us to reduce the overhead
     * of repeated requests by sharing a request hash with the server.
     * This link also improves compatibility with CDN access by allowing
     * graphql over GET requests
     */
    persistedQueryLink = createPersistedQueryLink({
      sha256,
      useGETForHashedQueries: true,
      disable: (error) => {
        if (error.networkError) {
          return true;
        }
        return false;
      },
    });
  }
} catch (e) {} // eslint-disable-line no-empty

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, response }) => {
    const captureException = (
      message: string,
      level: SeverityLevel,
      extra?: Record<string, unknown>
    ) => {
      Sentry.withScope((scope) => {
        scope.setLevel(level);
        Sentry.captureException(message, {
          tags: {
            graphql: true,
          },
          extra: {
            ...extra,
            operation,
            response,
          },
        });
      });
    };

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        captureException(`[GraphQL error]: Message: ${message}`, 'error', {
          path,
          locations,
        });
      });
    }
    if (networkError) {
      captureException(`[Network error]: ${networkError}`, 'info');
    }
  }
);

export const apolloCache = new InMemoryCache({
  typePolicies: {
    CurrentUserAndOrgs: {
      keyFields: ['userId'],
    },

    Query: {
      fields: {
        getAllVisits: {
          merge(_, incoming) {
            // Always take the latest data instead of merging with existing cache
            return incoming;
          },
        },
      },
    },
  },
});

const createApolloClient = (
  accessToken: string | null,
  tenantId: string | null
) => {
  const authLink = setContext((_, { headers }) => ({
    headers: generateGraphQLHeaders(
      accessToken,
      tenantId,
      ClientHeadersByApp.USER_UPLOAD
    ),
  }));

  return new ApolloClient({
    name: config.appName ?? 'unknown-react-dashboard-app',
    version: config.appVersion ?? '0.0',
    link: from([
      errorLink,
      authLink,
      ...(persistedQueryLink ? [persistedQueryLink] : []),
      httpLink,
    ]),
    cache: apolloCache,
    credentials: 'include',
    resolvers: {},
  });
};

const ApolloContext = createContext<ApolloContextValue>({
  tenantId: null,
  setTenantId: () => null,
});

export const ApolloContextProvider: FC = ({ children }) => {
  const [tenantId, setTenantId] = useState<string | null>(null);
  const accessToken = useAccessToken();

  const apolloClient = useMemo(
    () => createApolloClient(accessToken, tenantId),
    [accessToken, tenantId]
  );

  return (
    <ApolloContext.Provider value={{ tenantId, setTenantId }}>
      <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
    </ApolloContext.Provider>
  );
};

export const useApolloContext = (): ApolloContextValue => {
  return useContext(ApolloContext);
};
