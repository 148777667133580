import common from './common.json';
import error from './error.json';
import manual_upload from './manual_upload.json';
import csv_upload from './csv_upload.json';

export const de = {
  common,
  error,
  manual_upload,
  csv_upload,
};
