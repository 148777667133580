import { ButtonV4 } from '@robinpowered/design-system';
import { useTranslation } from 'react-i18next';
import ManualUploadForm from './ManualUploadForm';
import EmailInvitesToggle from './EmailInvitesToggle';
import {
  PeopleTableRow,
  HandleInputChange,
  HandleEmailBlur,
  HandleAddPeople,
} from '../types';
import Header from '../../../sharedComponents/Header';
import CardWithHeader from '../../../sharedComponents/CardWithHeader';
import Card from '../../../sharedComponents/Card';
import CancelButton from '../../../sharedComponents/CancelButton';
import {
  FormFlexContainer,
  EmailInviteFlexContainer,
  Label,
  LabelRow,
  ManualUploadCardContainer,
  EmailInvitesHeader,
} from './styles';
import styled from '@emotion/styled';

interface Props {
  peopleRows: Array<PeopleTableRow>;
  addFormRow: () => void;
  handleAddPeople: HandleAddPeople;
  handleInputChange: HandleInputChange;
  handleEmailInputChange: HandleInputChange;
  handleEmailBlur: HandleEmailBlur;
  removeRow: (rowIdx: number) => void;
  sendEmailInvite: boolean;
  toggleEmailInvite: () => void;
  handleExit: () => void;
  enableAddPeopleButtons: boolean;
  formSubmitted: boolean;
}

const ManualUploadUi = ({
  peopleRows,
  addFormRow,
  handleAddPeople,
  handleInputChange,
  handleEmailInputChange,
  handleEmailBlur,
  removeRow,
  sendEmailInvite,
  toggleEmailInvite,
  handleExit,
  enableAddPeopleButtons,
  formSubmitted,
}: Props): JSX.Element => {
  const { t } = useTranslation('manual_upload');

  return (
    <>
      <Header>
        <>
          <CancelButton
            variant="tertiary"
            size="default"
            onClick={handleExit}
            disabled={formSubmitted}
          >
            {t('header.cancel_button')}
          </CancelButton>
          <ButtonV4
            label={t('header.add_poeple_button')}
            variant="primary"
            onClick={() => handleAddPeople({ sendEmailInvite })}
            disabled={!enableAddPeopleButtons}
            isLoading={formSubmitted}
          />
        </>
      </Header>
      <ManualUploadCardContainer>
        <FormFlexContainer>
          <CardWithHeader
            headerContent={
              <LabelRow>
                <StyledLabel htmlFor="email">
                  {t('form.email_header')}
                </StyledLabel>
                <Label htmlFor="fullName">{t('form.full_name_header')}</Label>
              </LabelRow>
            }
          >
            <ManualUploadForm
              handleInputChange={handleInputChange}
              handleEmailInputChange={handleEmailInputChange}
              handleEmailBlur={handleEmailBlur}
              peopleRows={peopleRows}
              addFormRow={addFormRow}
              removeRow={removeRow}
            />
          </CardWithHeader>
        </FormFlexContainer>

        <EmailInviteFlexContainer>
          <Card>
            <EmailInvitesHeader>
              {t('email_invites_card.header')}
            </EmailInvitesHeader>
            <EmailInvitesToggle
              sendEmailInvite={sendEmailInvite}
              toggleEmailInvite={toggleEmailInvite}
            />
          </Card>
        </EmailInviteFlexContainer>
      </ManualUploadCardContainer>
    </>
  );
};

const StyledLabel = styled(Label)`
  margin-right: 35px;
`;

export default ManualUploadUi;
